import { CodeError } from './error'
import type { Database } from './supabase/database.types'

type Tables<T extends keyof Database['public']['Tables']> =
  Database['public']['Tables'][T]['Insert']

export async function select_user(select_columns: string | undefined) {
  const supabase = useSupabaseClient<Database>()
  const { data, error } = await supabase
    .from('user')
    .select(select_columns)
    .single()
  if (error) {
    const err = new CodeError(`[supabase error] select user select_columns ${select_columns}`, '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }

  return { data, error }
}

export async function check_exist_user() {
  const supabase = useSupabaseClient<Database>()
  const { data, error } = await supabase
    .from('user')
    .select()
    .single()
  if (error)
    return null
  return data
}

export async function insert_error(error_message?: string, error_code?: string, stack_trace?: string, custom_note?: string) {
  const supabase = useSupabaseClient<Database>()
  await supabase
    .from('error_log')
    .insert({ error_message, error_code, stack_trace, custom_note, type: 'homepage' })
}

export async function insert_payment(query?: any) {
  const supabase = useSupabaseClient<Database>()

  // auth_user_id 키가 있는지 확인
  if (!Object.prototype.hasOwnProperty.call(query, 'auth_user_id')) {
    const user = useSupabaseUser()
    query.auth_user_id = user.value.id
  }

  const { data, error } = await supabase
    .from('payment')
    .insert(query)
  if (error) {
    const err = new CodeError('[supabase error] insert payment', '003')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }

  return {
    data,
    error,
  }
}

export async function select_dashboards(
  select_columns?: string | undefined,
) {
  const supabase = useSupabaseClient<Database>()

  const { data, error } = await supabase
    .from('dashboard')
    .select(select_columns)
  if (error) {
    const err = new CodeError('[supabase error] select dashboard', '002')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }

  return { data, error }
}

export async function select_dashboard_where_dashboard_idx(
  dashboard_idx: number,
  select_columns?: string | undefined,
) {
  const supabase = useSupabaseClient<Database>()

  const { data, error } = await supabase
    .from('dashboard_block')
    .select(select_columns)
    .eq('dashboard_idx', dashboard_idx)
  if (error) {
    const err = new CodeError('[supabase error] select dashboard', '001')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }
  return { data, error }
}

export async function select_dashboard_Block(
  select_columns?: string | undefined,
) {
  const supabase = useSupabaseClient<Database>()

  const { data, error } = await supabase
    .from('dashboard')
    .select(select_columns)
  if (error) {
    const err = new CodeError('[supabase error] supabase.auth.signUps', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }
  return { data, error }
}

export async function get_plan() {
  const supabase = useSupabaseClient<Database>()

  // TODO subscription_start_date, subscription_end_date 사이에 값을 가져오는 로직 추가 필요
  const { data, error } = await supabase
    .from('subscription')
    .select('plan ( name )')
    .order('created_at', { ascending: false })
    .limit(1)
    .single()

  if (error) {
    const err = new CodeError('[supabase error] select plan', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }
  if (!data) {
    const err = new CodeError('Failed to load plan', '999')
    await insert_error('subscription select data null', err.code, err.stack, err.message)
    return {
      data: null,
      err,
    }
  }
  const plan = (data as any).plan.name
  return { data: plan, error: null }
}

export async function update_user(options: {
  language?: string
  timezone?: string
  name?: string
}) {
  const { language, timezone, name } = options
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()
  const query: Tables<'user'> = {}
  if (language)
    query.language = language

  if (timezone)
    query.timezone = timezone

  if (name)
    query.name = name

  const { error } = await supabase
    .from('user')
    .update(query)
    .eq('auth_user_id', user.value.id)
  if (error) {
    const err = new CodeError(`[supabase error] update user auth_user_id: ${user.value.id}`, '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
  }
  return { error }
}

export async function delete_account() {
  const supabase = useSupabaseClient<Database>()

  const { data, error } = await supabase.rpc('delete_own_account' as never)
  if (error) {
    const err = new CodeError('[supabase error] function delete_own_account', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
  }

  return { data, error }
}

export async function unsubscription() {
  const supabase = useSupabaseClient()

  const { data, error } = await supabase.rpc('unsubscription')
  if (error) {
    const err = new CodeError('[supabase error] function unsubscription', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
  }

  return { data, error }
}

export async function get_payment_history() {
  const supabase = useSupabaseClient()

  const { data, error } = await supabase.rpc('payment_history' as never)
  if (error) {
    const err = new CodeError('[supabase error] function payment_history', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
  }

  return { data, error }
}

export function getPagination(page: number, size: number) {
  const limit = size ? +size : 10 // 기본 페이지 크기를 10으로 설정
  const from = page ? (page - 1) * limit : 0
  const to = page ? from + limit - 1 : limit - 1

  return { from, to }
}

export async function getTaskListPagination({ query: { page = 1 } }) {
  const supabase = useSupabaseClient<Database>()
  const { from, to } = getPagination(page, 10)
  const { data, count, error } = await supabase
    .from('dashboard')
    .select('*', { count: 'exact' })
    .eq('is_deleted', 'N')
    .order('idx', { ascending: false })
    .range(from, to)

  if (error) {
    const err = new CodeError('[supabase error] select dashboard from getTaskListPagination', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
  }
  return {
    pageRows: data,
    totalRows: count,
  }
}

export async function delete_dashboard(dashboard_idx: number) {
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()
  const { data, error, status } = await supabase
    .from('dashboard')
    .update({ is_deleted: 'Y' })
    .eq('auth_user_id', user.value.id)
    .eq('idx', dashboard_idx)
  if (error) {
    const err = new CodeError('[supabase error] update dashboard from delete_dashboard', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
      status: null,
    }
  }
  return { data, error, status }
}

export async function update_dashboard(dashboard_idx: number, query: any = {}) {
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()
  const { data, error } = await supabase
    .from('dashboard')
    .update(query)
    .eq('auth_user_id', user.value.id)
    .eq('idx', dashboard_idx)
  if (error) {
    const err = new CodeError('[supabase error] update dashboard from update_dashboard', '999')
    await insert_error(JSON.stringify(error), err.code, err.stack, err.message)
    return {
      data: null,
      error,
    }
  }
  return { data, error }
}
